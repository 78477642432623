import { createContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { extractActiveSource } from "../components/helpers/extractActiveSource";
import {
  listIntegrations,
  listFields,
  listMembers,
  canEditTeamIds,
  listObjectsFields,
  fetchDomainToggle,
  getIdeaPageDisplay,
  listOwners,
} from "../Api";
import { ChildProps, IdeaPriority, syncContextType } from "../types/types";
import {addOptionsToFilters} from "./helpers";
import {FiltersSpec} from "../components/ideas/filters/FiltersSpec";

const initialState: syncContextType = {
  sync: "",
  fieldsLoaded: false,
  activeSource: null,
  fields: null,
  components: null,
  ideaStatuses: null,
  ideaPriorities: null,
  requestPriorities: null,
  logout: null,
  members: null,
  owners: null,
  objectCustomizations: null,
  editCustomizations: null,
  defaultPriority: null,
  defaultStatus: null,
  getFields: null,
  priorityData: null,
  domains: [],
  requestPrioritiesColors: null,
  defaultRequestPriority: null,
  accountAdditionalInfo: null,
  opportunityAdditionalInfo: null,
  showDomain: false,
  evidenceDomain: false,
  displayState: null,
  allComponents: [],
  businessCategoryMapping: null,
  currentAccount: null,
  ideasTableDisplay: null,
  customEvidenceObj: null,
  accountDoc: null,
  impactAggregationProperties: [],
  perspectiveConfigurations: [],
  ideaColumns: [],
  setIdeasFiltersConfig: null,
  ideasFiltersConfig: {
    evidence:{},
    idea:{}
  },
  ideaInfoTabsConfig: {}
};

interface DomainProps {
  accountId: string;
  name: string;
  value: string;
  _id: string;
}
interface DisplayState {
  [value: string]: boolean;
}

const SyncContext = createContext<syncContextType>(initialState);

const SyncContextProvider = ({ children }: ChildProps) => {
  const auth0 = useAuth0();
  const [fieldsLoaded, setFieldsLoaded] = useState<boolean>(false);
  const [activeSource, setActiveSource] = useState<any>(null);
  const [fields, setFields] = useState<any>(null);
  const [currentAccount, setCurrentAccount] = useState<any>(null);
  const [showDomain, setShowDomain] = useState<boolean>(false);
  const [components, setComponents] = useState<any[]>([]);
  const [allComponents, setAllComponents] = useState<any[]>([]);
  const [ideaStatuses, setIdeaStatuses] = useState<any>(null);
  const [ideaPriorities, setIdeaPriorities] = useState<any>(null);
  const [requestPriorities, setRequestPriorities] = useState<any[]>([]);
  const [priorityData, setPriorityData] = useState<any[]>([]);
  const [sync, setSync] = useState("");
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [editCustomizations, setEditCustomizations] = useState<any>();
  const [objectCustomizations, setObjectCustomizations] = useState<any>();
  const [defaultPriority, setDefaultPriority] = useState<any>();
  const [defaultStatus, setDefaultStatus] = useState<any>();
  const [domains, setDomains] = useState<DomainProps[]>([]);
  const [requestPrioritiesColors, setRequestPrioritiesColors] = useState<any>(
    {}
  );
  const [defaultRequestPriority, setDefaultRequestPriority] = useState<any>();
  const [accountAdditionalInfo, setAccountAdditionalInfo] = useState<any>(null);
  const [opportunityAdditionalInfo, setOpportunityAdditionalInfo] =
    useState<any>(null);
  const [evidenceDomain, setEvidenceDomain] = useState<boolean>(false);
  const [displayState, setDisplayState] = useState<DisplayState>({});
  const [businessCategoryMapping, setBusinessCategoryMapping] = useState<any[]>([]);
  const [impactAggregationProperties, setAgregationProperties] = useState<any[]>([]);
  const [perspectiveConfigurations, setPerspectiveConfigurations] = useState<any[]>([]);
  const [ideaColumns, setIdeaColumns] = useState<any[]>([]);
  const [ideaInfoTabsConfig, setIdeaInfoTabsConfig] = useState<any>({});
  const [ideasFiltersConfig, setIdeasFiltersConfig] = useState<{
    evidence:any,
    idea:any
  }>({
    evidence:{},
    idea:{}
  });

  const [ideasTableDisplay, setIdeasTableDisplay] = useState<any>({
    pendingSuggestions: false,
    customEvidenceTypes: false,
  });
  const [customEvidenceObj, setCustomEvidenceObj] = useState();
  const [accountDoc, setAccountDoc] = useState();

  const logout = () => {
    localStorage.clear();
  };

  const getDefaultpriorityAndStatus = async () => {
    const priorityRes = await listObjectsFields("priority", auth0);
    const priorityDefault = priorityRes?.options.find(
      (o: any) => o.isDefault === true
    );
    setDefaultPriority(priorityDefault?.key ?? "");
    const statusRes = await listObjectsFields("status", auth0);
    const statusDefault = statusRes?.options.find(
      (o: any) => o.isDefault === true
    );
    setDefaultStatus(statusDefault?.key ?? "");
  };

  const loadDomainEvidenceDisplay = () => {
    getIdeaPageDisplay("evidence", auth0)
      .then((res) => {
        setEvidenceDomain(res.domain);
      })
      .catch((e) => console.log(e));
  };

  // console.log({objectCustomizations})

  const getFields = () => {
    setFieldsLoaded(false);
    listFields(auth0)
      .then((res) => {
        setFields(res);
        setComponents(res.components || []);
        setDomains(res.domains || []);
        const sortedStatuses = res.ideaStatuses.sort(
          (a: any, b: any) => a.index - b.index
        );
        setIdeaStatuses(sortedStatuses || []);
        const sortedIdeaPriorities = res.ideaPriorities.sort(
          (a: any, b: any) => a.index - b.index
        );
        setIdeaPriorities(sortedIdeaPriorities || IdeaPriority);
        const sortedRequestPriorities = res.requestPriorities.sort(
          (a: any, b: any) => a.index - b.index
        );
        setRequestPriorities(sortedRequestPriorities || []);
        setObjectCustomizations(res?.customizations || {});
        setCustomEvidenceObj(res?.customizations?.customEvidenceObj || {});
        setAllComponents(res.allComponents || []);
        setAgregationProperties(res.impactAggregationProperties || []);
        setPerspectiveConfigurations(res.perspectiveConfigurations || []);
        // console.log({ideaColumnsConfig: res.ideaColumnsConfig})
        setIdeaColumns(res.enabledColoumns || []);
        // console.log({["------ideaInfoTabsConfig:"]: res.ideaInfoTabsConfig})
        setIdeaInfoTabsConfig(res.ideaInfoTabsConfig || {});

        setIdeasFiltersConfig(res.ideasFiltersConfig || {})
        // console.log({ideasFiltersConfig})

        const accountFields = res?.customizations?.account?.fields || null;
        const accountAdditionalInfoObj: any = {};
        if (accountFields) {
          for (const k in accountFields) {
            if (accountFields[k].isExtra) {
              accountAdditionalInfoObj[k] = accountFields[k];
            }
          }
        }
        setAccountAdditionalInfo(accountAdditionalInfoObj);
        setAccountDoc(res?.accountDoc);

        const opportunityFields =
          res?.customizations?.opportunity?.fields || null;
        const opportunityAdditionalInfoObj: any = {};
        if (opportunityFields) {
          for (const k in opportunityFields) {
            if (opportunityFields[k].isExtra) {
              opportunityAdditionalInfoObj[k] = opportunityFields[k];
            }
          }
        }
        setOpportunityAdditionalInfo(opportunityAdditionalInfoObj);
        const priorities = res.requestPriorities;
        const requestPriorityDefault = priorities?.find(
          (o: any) => o.isDefault === true
        );
        setDefaultRequestPriority(requestPriorityDefault.label);

        if (res.requestPriorities) {
          const copy = [...res.requestPriorities];
          const priorities = copy.map((r: any) => r.label);
          setPriorityData(priorities ?? []);
          const secondCopy = [...res.requestPriorities];

          const priorityColorObject: any = {};
          secondCopy.forEach((s: any) => {
            priorityColorObject[s.label] = s.color || s.chartColor;
          });

          setRequestPrioritiesColors(priorityColorObject);
        }

        if (res.currentAccount) {
          setCurrentAccount(res.currentAccount);
        }
        setBusinessCategoryMapping(
          res.businessCategoryMapping[0]?.businessNames || []
        );
        setFieldsLoaded(true);
      })
      .catch((e) => console.log(e));
  };
  // const getListFieldsCustomizationsList = () => {
  //   setFieldsLoaded(false);
  //   listFieldsCustomizationsList(auth0)
  //     .then((res) => {
  //       setObjectCustomizations(res?.customizations || {});
  //     })
  //     .catch((e) => console.log(e));
  // };


  const loadCanEditTeamIds = () => {
    canEditTeamIds(auth0)
      .then((customizations: any) => {
        setEditCustomizations(
          customizations.reduce((acc: any, customization: any) => {
            return {
              ...acc,
              [customization.name]: customization.value,
            };
          }, {})
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadMembers = () => {
    listMembers(auth0)
      .then((users) => {
        setMembers(users);
      })
      .catch((e) => console.log(e));
  };

  const loadOwners = () => {
    listOwners(auth0)
      .then((users) => {
        setOwners(users);
      })
      .catch((e) => console.log(e));
  };

  const getListIntegrationsFn = () => {
    listIntegrations(auth0)
      .then((response) => {
        const active = extractActiveSource(response);
        const activeToString = JSON.stringify(active);
        const typeIntegration = active.type;
        setActiveSource(active);
        setSync(typeIntegration);
        localStorage.setItem("active-source", activeToString);
      })
      .catch((e) => console.log(e));
  };

  const fetchDomainToggleFn = async () => {
    await fetchDomainToggle(auth0).then((res) => {
      const toggle = res.toggle[0].useDomains;
      setShowDomain(toggle);
    });
  };

  const loadIdeaPageDisplay = () => {
    getIdeaPageDisplay("idea", auth0)
      .then((res) => {
        setDisplayState(res);
        console.log(res)
      })
      .catch((e) => console.log(e));
  };

  const loadIdeasTableDisplay = () => {
    getIdeaPageDisplay("ideas", auth0)
      .then((res) => {
        setIdeasTableDisplay(res);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (members.length > 0 || owners.length > 0) {
      const updatedFilters = addOptionsToFilters(ideasFiltersConfig, { 
        members, 
        owners,
        components
      });
      // console.log({updatedFilters, FiltersSpec})
      setIdeasFiltersConfig(updatedFilters);
    }
  }, [fields, members, owners]);

  useEffect(() => {
    loadIdeasTableDisplay();
    loadIdeaPageDisplay();
    getFields();
    loadOwners();
    loadMembers();
    loadCanEditTeamIds();
    getDefaultpriorityAndStatus();
    fetchDomainToggleFn();
    loadDomainEvidenceDisplay();
    // getListFieldsCustomizationsList();

    const activeFromStorage = localStorage.getItem("active-source") ?? "";
    if (activeFromStorage === "" || activeFromStorage == "undefined" || activeFromStorage == undefined) {
      getListIntegrationsFn();
    } else if (activeFromStorage !== "") {
      const activeParsed = JSON.parse(activeFromStorage);
      setActiveSource(activeParsed);
      setSync(activeParsed.type);
    }
  }, []);

  return (
    <>
      <SyncContext.Provider
        value={{
          sync,
          fieldsLoaded,
          activeSource,
          fields,
          components,
          ideaStatuses,
          ideaPriorities,
          logout,
          members,
          owners,
          objectCustomizations,
          editCustomizations,
          defaultPriority,
          defaultStatus,
          getFields,
          requestPriorities,
          priorityData,
          domains,
          requestPrioritiesColors,
          defaultRequestPriority,
          accountAdditionalInfo,
          opportunityAdditionalInfo,
          showDomain,
          evidenceDomain,
          displayState,
          allComponents,
          businessCategoryMapping,
          currentAccount,
          ideasTableDisplay,
          customEvidenceObj,
          accountDoc,
          impactAggregationProperties,
          perspectiveConfigurations,
          ideaColumns,
          ideasFiltersConfig,
          setIdeasFiltersConfig,

          ideaInfoTabsConfig
        }}
      >
        {children}
      </SyncContext.Provider>
    </>
  );
};

export { SyncContext, SyncContextProvider };
export default SyncContext;
